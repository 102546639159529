import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Layout, Link, PostGrid, PostLink, SEO, WaveSection } from 'src/components';
import { get } from 'src/utils';

const H3 = styled.h3`
  margin-top: 0;
`;

const IndexPage = ({ data }) => {
  const edges = get(data, 'allMarkdownRemark', 'edges') || [];
  return (
    <Layout>
      <SEO title="Home" />
      <WaveSection>
        <h1 className="intro-title">Welcome!</h1>
        <p>
          I'm Andrew, a software engineer from Phoenix, AZ.
          <br />I work at <Link to="https://genbloq.com/">Genbloq</Link>, helping medium and large companies collaborate
          via emerging blockchain technologies. In my free time I work on{' '}
          <Link to="https://stacktrends.dev/">StackTrends</Link> and{' '}
          <Link to="https://www.lashfancy.com/">Lash Fancy</Link>. Thanks for visiting!
        </p>
        <br />
        <p>
          <Link to="/blog" button>
            All Posts
          </Link>
          &nbsp;
          <Link to="/projects" secondary button>
            Projects
          </Link>
          &nbsp;
          <Link to="/resume" secondary button>
            Resume
          </Link>
        </p>
      </WaveSection>
      {edges.length ? (
        <>
          <H3>Latest Posts</H3>
          <PostGrid>
            {edges.map((edge, i) => (
              <PostLink key={edge.node.id} featured={i === 0} post={edge.node} />
            ))}
          </PostGrid>
        </>
      ) : null}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      filter: { frontmatter: { tags: { in: ["home"] } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            featuredImage {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            path
            title
            subtitle
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;
